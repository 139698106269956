import React, { useContext,useState, useEffect } from "react";
import { CartContext } from "../../context/cart/cart-context";
import { useHistory, useParams } from "react-router-dom";

import CartItem from "./cart-item";
import Total from "./total";
import UpsellItem from "./upsell";
//import PriceBreakdowns from "./breakdown";
import EndUserSideBar from "../../components/layouts/EndUserSideBar";
import { useSelector } from 'react-redux';
import {UpsellCard} from '../../components/upsell/upsell-card';
import "./cart-page.styles.scss";
import Popup from "../../components/Popup";
import { getPromocodeDetails } from "../../services/eventService";
import Notification from '../../components/Notification';
import CartIsEmpty from '../../components/controls/CartIsEmpty';
import Cookies from 'universal-cookie';
import ConfirmDialog from "../../components/ConfirmDialog";
import Hidden from '@material-ui/core/Hidden';

import {
  Grid,
  Box,
  Divider,
  CardContent,
  Typography,
  Button,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  useMediaQuery,
} from "@material-ui/core";
import { loadCart } from "../../services/payment";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  breadcrumbs: {
    margin: theme.spacing(2),
  },


  content: {
    padding: theme.spacing(2),
    paddingBottom: '60px',
    marginTop: '64px !important',
    [theme.breakpoints.down('xs')]: {
      height: `calc(100% - 250px)`,
      margin: theme.spacing(1),
      padding: '16px 0px'
    },
  },
  cardHeader: {
    padding: '10px 0px',
    fontSize: '16px',
    textAlign: 'left',
    marginBottom: '0px',
    color: '#000',
  },
  eventHeight: {
    position: 'relative',
    overflowY: 'auto',
    zIndex: '1',
  }

}));

const CartPage = () => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const history = useHistory()
  const { reservationIdParam } = useParams();
  const widget  = useSelector((state)=>state.widgetReducer);
  const cookies = new Cookies();
  const {
    cartItems,
    currency,
    increase,
    decrease,
    removeProduct,
    upsellIncrease,
    upsellDecrese,
    removeUpsell,
    updateddiscount,
    changePrice,
    loadReservedCart,
    readOnlyCart,
    reservationId,
    setCartEditable,
    increaseVariant,
    decreaseVariant
  } = useContext(CartContext);
  const funcs = { increase, decrease, removeProduct, upsellIncrease, upsellDecrese, removeUpsell, changePrice, setCartEditable, increaseVariant, decreaseVariant };
  const classes = useStyles();
  const [arrayValue,setArrayValue] = useState(0);
   const [openPopup, setOpenPopup] = useState(false);
  const [promoCode, setPromoCode] = useState(cartItems.length>0?cartItems[0].discount?cartItems[0].discount[0].promocode:'':'');
  const [condition, setCondition] = useState(false);
  const [notify, setNotify] = useState({ isOpen: false, message: '', type: '' });
  const results = cartItems.length > 0 ? cartItems.reduce(function (r, a) {
    r[a._id] = r[a._id] || [];
    r[a._id].push({...a, individualPrice: true});
    return r;
  }, Object.create(null)) : [];

  const onConfirmEditReservation = () => {
    setCartEditable();
    setConfirmEditCartDialog({
      ...confirmEditCartDialog,
      isOpen: false,
    })
  }
  let scrollers = document.getElementsByClassName('MuiTableContainer-root');

  let scrollerDivs = Array.prototype.filter.call(scrollers, function(testElement) {
    return testElement.nodeName === 'DIV';
  });

  function scrollAll(scrollLeft) {  
     scrollerDivs.forEach(function(element, index, array) {
    element.scrollLeft = scrollLeft;
  }); }
  scrollerDivs.forEach(function(element, index, array) {
      element.addEventListener('scroll', function(e) {
        scrollAll(e.target.scrollLeft);
      },false);
    });

  const [confirmEditCartDialog, setConfirmEditCartDialog] = useState({
    isOpen: false,
    title: 'Edit Reservation',
    subTitle: 'Are you sure you want to edit your reservation? Your tickets will be released and you can continue to make changes. ',
    onConfirm: onConfirmEditReservation,
    });
  const back = () => {
    history.push("/");
  }
  const is_boxoffice_ui = widget.channel === 'boxoffice';
  const openUpsellModal = (e) => {
    const findUpsellValue = cartItems.find(x => x._id === e);
    setArrayValue(findUpsellValue);
    setOpenPopup(true);
  }
  const backtodetail = () => {
    if (cartItems.length) {
      history.goBack()
    } else {
      history.push('/detail/'+widget.eventid);
    }
  }
  const notification=(msg,types)=>{
    setNotify({
      isOpen: true,
      message: msg,
      type: types
    })
  }

  useEffect(()=>{
    localStorage.removeItem('eventAccountIDForUpsell');
    if(cartItems.length>0&&cartItems[0].discount!==undefined&&cartItems[0].discount.length>0){
      handle();
    }else{
      removediscount();
    }
  },[])

  const removediscount = () => {
    if (cartItems.length > 0) {
      for (let a = 0; a < cartItems.length; a++) {
        updateddiscount({ _id: cartItems[a]._id, discount: undefined, tickettypeid: cartItems[a].tickettypeid })
      }
    }
    setPromoCode('');
    setCondition(false);
  }

 useEffect(()=> {
    if (reservationIdParam) {
      loadCart(reservationIdParam).then(result => {
        const promocodeChecking = result.cartItems.length > 0 && result.cartItems[0].discount ? result.cartItems[0].discount[0].promocode : "";
        setPromoCode(promocodeChecking);
        setCondition(promocodeChecking !== "");
        loadReservedCart(reservationIdParam, result);
        notification(`Reservation ${reservationIdParam} loaded.`,'success')
      }).catch(err => {
        notification(err.response?.data?.error || err.message,'error')
      });
    }
},[reservationIdParam])

 const clickLoadReservedCartButton = () => {
  const reservationId = window.prompt('Reservation Id?');
  if (reservationId) {
    loadCart(reservationId).then(result => {
      loadReservedCart(reservationId, result);
      notification(`Reservation ${reservationId} loaded.`,'success')
    }).catch(err => {
      notification(err.response?.data?.error || err.message,'error')
    });
  }
 }

  const handle = () => {

    const cartItemsIds = cartItems.map(item => {
      return item.tickettypeid
    });

    getPromocodeDetails({
      "action_type" :"promocode list",
      "promo_code":promoCode,
      "account_id": widget.customerid!==''?widget.customerid:'1',
      "event_tiers": cartItemsIds,
   }).then(result=>{
    
     let checkArray = []

     if(result.data && result?.data?.length > 0){

      cartItems.forEach(x=>{
        let filteredEvent = result.data.find(
          (e) =>e.eventdetailid?.includes(x._id)      
        ); 
        if (result.isGiftCard) {
          //set the event to be the first event
          filteredEvent = result.data[0];
        }
        if (!filteredEvent) {
          return;
        }
        if (result.isGiftCard) {
          if (Number(filteredEvent.discountvalue) <= 0) {
            setCondition(false);
            notification("The Gift Card has been fully redeemed",'error');
          }
        }
        if (filteredEvent!==undefined && filteredEvent.discountvalue) {
          checkArray.push(filteredEvent)
        }
        
      })
      if (checkArray.length === cartItems.length) {
        if (result.isGiftCard || Number(checkArray[0].activedaterange)===1) {
          setCondition(true);
          for(let a=0;a<checkArray.length;a++) {
            if(cartItems[a].tickettypeid) {

              const hasPromoCodeTicketTypesIds = checkArray.some(checkItem => Array.isArray(checkItem.promoCodeTicketTypesIds));

              if (hasPromoCodeTicketTypesIds) {
                  let containTicketTypeId = checkArray.some(checkItem => 
                      checkItem.promoCodeTicketTypesIds.includes(cartItems[a].tickettypeid)
                  );
                  if (!containTicketTypeId) {
                      continue;
                  }
              }
    
              updateddiscount({ _id: cartItems[a]._id, discount: checkArray, tickettypeid: cartItems[a].tickettypeid })
            }
          }  
        } else {
          for(let a=0;a<checkArray.length;a++) {
            updateddiscount({ _id: cartItems[a]._id, discount: undefined, tickettypeid: cartItems[a].tickettypeid })
          }
          setCondition(false);
          notification("The Promo Code is invalid",'error')
        }
      } else {
        for (let a=0;a<checkArray.length;a++) {
          updateddiscount({ _id: cartItems[a]._id, discount: undefined, tickettypeid: cartItems[a].tickettypeid })
        }
        if (condition) {
          setCondition(false);
          notification('This Promo Code is unavailable for this Event','error')
        }        
      }
    }else{
       for (let a = 0; a < checkArray.length; a++) {
         updateddiscount({ _id: cartItems[a]._id, discount: undefined, tickettypeid: cartItems[a].tickettypeid })
       }
        setCondition(false);

      if(result.errorMessage) {
        notification(result.errorMessage, 'error')  
      } else {
        notification('Please enter valid PromoCode', 'error')  
      }
    }
   }).catch(error=>{
     setCondition(false);
     updateddiscount({ _id: cartItems[0]._id, discount: undefined, tickettypeid: cartItems[0].tickettypeid })
     const msg = (error.response) && !error.response.data.message ? error.response.data.error ? error.response.data.error : error.response.data : error.message;
     notification(msg, 'error')
   })
      }

  const goPreviousPage = () => {
    if (!widget || !widget.eventid || widget.eventid.includes(",")) {
      removediscount()
      back();
    }
    else {
      removediscount()
      backtodetail();
    }
  }
  return (
  
    <div className="cart-widgetpage">
     <Notification
        notify={notify}
        setNotify={setNotify}
      />
      <EndUserSideBar />
      <main>
        <Hidden smDown>
          <Box sx={{ display: 'flex'}} className="page-heading">
            <Box sx={{ flexGrow: 1}} className="mt-1">
              <Typography variant="h5" className="title">
              {!isMobile ? "Cart": null}
              </Typography>
            </Box><Box>
              {!isMobile ? <>
                  <Button color="default" size="small" onClick={goPreviousPage} disabled={readOnlyCart} className="btn-redoutline">Go back</Button>
                {/* <Button color="default" size="small" onClick={clickLoadReservedCartButton}  mr={2} className="btn-redoutline">Load Reservation</Button> */}
              </> : null}
            </Box>
          </Box>
        </Hidden>

        {cartItems.length === 0 ? (
          <CartIsEmpty />
          
        ) : (
          <>
            <card className="cart-page">
              <CardContent> 
               
                  <Grid item xs={12} lg={12}>
                    <Grid container spacing={2} justifyContent="center">
                      <Grid item lg={9} md={9} sm={8} xs={12}>
                        <div className="cart-bdr">
                        <h6 className={classes.cardHeader}> Your Order{reservationId ? `, Reservation Id:  ${reservationId}`: null}</h6>  
                        { reservationId && is_boxoffice_ui ? <Button className="link-red btn-sm" onClick={() => setConfirmEditCartDialog({
                          ...confirmEditCartDialog,
                          isOpen: true,
                        })}>Edit Reservation</Button>: null}
                        <Box>
                        {cartItems.length > 0 ? Object.values(results).map(cart => { return ( 
                          <div>
                              {cart.length > 0 && <div className="cart-item">
                                <TableContainer>
                                  <Table className="event-tbl" aria-label="customized table">
                                    <TableHead>
                                      <TableRow>
                                        <td>Event Name</td>
                                        {is_boxoffice_ui && cart[0]?.allocation_name ? <td align="left">Allocation</td> : null}
                                        <td align="left"> Price <span  className="headIcon" ></span></td> 
                                        <td align="left">{isMobile ? "Qty" : "Quantity" }<span className="headIcon" ></span></td> 
                                        <td align="right">Subtotal </td>
                                      </TableRow>
                                    </TableHead>
                                  </Table>
                                </TableContainer>
                              </div>}
                            {cart.map((item, itemIndex, row) => {
                              return (
                                <div className="cart-group" key={itemIndex}>
                                  <CartItem  {...item} key={itemIndex} {...funcs} itemIndex={itemIndex} currency={currency} reservationId={reservationId} isMobile = {isMobile}/>
                                  {item && item.upsell && (item.upsell).length > 0 && item.upsell.map((upsell, upsellKey) => {
                                    return <UpsellItem currency={currency} {...upsell} _id={item._id} key={upsell.upsellid} {...funcs} reservationId={reservationId}
                                     itemIndex={upsellKey} tickettypeid={upsell.tickettypeid} allocation_name = {item.allocation_name}  isMobile = {isMobile}/>
                                  })
                                  }
                                  {item.showUpsellButton && !readOnlyCart &&  (
                                    <Button title="Add Ons Available" className={'btn-redoutline'} onClick={() => openUpsellModal(item._id)}>
                                      + Add Ons Available
                                    </Button> 
                                  )}
                                 
                                </div>
                              )
                            })}
                            </div>
                        )
                        }) : null}
                        </Box>
                        </div>
                      </Grid>
                      <Grid item lg={3} md={3} sm={4} xs={12} className="booking-details">
                        <div className="cart-bdr p-0">
                        <Hidden smDown>
                          <h6 className={classes.cardHeader}> Order Summary</h6>                         
                        </Hidden>
                        <Grid item lg={12} md={12} sm={12} xs={12}>
                          <Total promoCode={promoCode} condition={condition}
                           setPromoCode = {setPromoCode} handle = {handle}
                           removediscount = {removediscount} isMobile = {isMobile}
                           goPreviousPage = {goPreviousPage}
                          />
                        </Grid>
                        
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                
              </CardContent>
            </card>
              <Popup title="Add Ons" openPopup={openPopup} setOpenPopup={setOpenPopup}>
                <UpsellCard eventParentId={arrayValue.eventParentId}
                  eventId={arrayValue._id} setPopupState={setOpenPopup}
                  currencysymbol={arrayValue.currencysymbol}
                  includingTax={arrayValue.includingTax}
                  normalTax={arrayValue.taxpercentage}
                  showDialogAction={true}
                  tickettypeid={undefined} />
      </Popup>
      <ConfirmDialog confirmDialog={confirmEditCartDialog} 
        setConfirmDialog={setConfirmEditCartDialog}>
        </ConfirmDialog>
            
          </>
        )}
      </main>
    </div>
  );
};

export default CartPage;
